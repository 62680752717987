import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import _ from 'lodash'

import Head from 'next/head'
import Image from "next/image"

import handleSeomaticMeta from "../lib/seomatic"

import { Header } from '../fuselage/global/header/header'
import { HeroUnit } from '../fuselage/blocks/hero-unit-post-event/hero-unit-post-event'
import { ContentCard } from "../fuselage/components/content-card/content-card"

import { QuerySEO } from "../graphql/queries"
import { GetBizzaboPartersByLevel } from '../lib/bizzabo-partner-levels'

import { HighLights } from "../fuselage/layout/homepage/highlights-post-event"
import { Topics } from "../fuselage/layout/homepage/topics"
import { FeaturedContent } from "../fuselage/layout/homepage/featured-content"
import { StayUpdated } from "../fuselage/layout/homepage/stay-updated"
import { SelectedSpeakers } from "../fuselage/layout/homepage/selected-speakers"
import { MeetGraphic, MeetGraphicBase } from "../fuselage/layout/homepage/meet-graphic"
import { MontageGrid, MontageBox, MontageSpacer, MontageImage } from '../fuselage/layout/homepage/montage'
import { Spinner } from "../fuselage/layout/homepage/spinner"
import { ArrowButton } from "../fuselage/components/arrow-button/arrow-button"


// legacy data
import { bzPartners } from "../data/bz-partners"


export default function Home({ seomatic, entry, craftCmsProfiles, speakerData, featuredContent }) {

	// console.log('seomatic:', seomatic)
	// console.log('entry:', entry)
	// console.log('speakerData:', speakerData)
	// console.log('partnerData:', partnerData)
	// console.log('featuredContent:', featuredContent)
	// console.log('craftCmsProfiles:', craftCmsProfiles)
	
	
	
	// selected bizzabo speakers [for slider]
	const selectedSpeakerIds = [ { id: 1544498 }, { id: 1544520 }, { id: 1544519 }, { id: 1570672 }, { id: 1544509 }, { id: 1635505 } ]
	const selectedSpeakers = _.intersectionBy(speakerData, selectedSpeakerIds, 'id')
	
	// selected cms speakers [for slider]
	const selectedCmsSpeakerIds = [ 
		{ slug: "scott-antel" },
		{ slug: "dirk-bakker" },
		{ slug: "thomas-emanuel" },
		{ slug: "nicolas-horky" },
		{ slug: "adam-konieczny" },
		{ slug: "silvija-lovreta" },
		{ slug: "george-tziallas" },
		{ slug: "hylko-versteeg" }, 
	]
	const selectedCmsSpeakers = _.intersectionBy(craftCmsProfiles, selectedCmsSpeakerIds, 'slug')
	// console.log('selectedCmsSpeakers:', selectedCmsSpeakers)


	return (
		<>
			<Head>
				{ handleSeomaticMeta(seomatic) }
				<meta name="facebook-domain-verification" content="abusgv8j2ztz09lbvhzze7mnnmj4yl" />
			</Head>

			<HeroUnit />

			<HighLights />

			<MontageGrid>

				<MontageBox span={2} bottom>
					<div className="block">
						<p className={`fs-0 caps lh-1 right c-white`}>IDEEA 2023 <br/>in photos</p>
					</div>
				</MontageBox>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-01.jpg`} width={3980} height={2200} span={2}/>

				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-04.jpg`} width={3980} height={2200} span={3}/>
				<MontageBox span={1}></MontageBox>

				<MontageSpacer />
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-sq-01.jpg`} width={1800} height={1800}/>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-03.jpg`} width={3980} height={2200} span={2}/>

				
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-sq-03.jpg`} width={1800} height={1800}/>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-43-04.jpg`} width={2400} height={1800} span={2}/>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-43-03.jpg`} width={2400} height={1800}/>

				<MontageBox span={1}></MontageBox>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-43-01.jpg`} width={2400} height={1800} span={2}/>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-43-02.jpg`} width={2400} height={1800} span={1}/>

				
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-02.jpg`} width={3980} height={2200} span={3}/>
				<MontageImage  src={`/assets/img/homepage/gallery/2023/gallery-43-05.jpg`} width={2400} height={1800} span={1}/>
			

				<MontageBox span={2}>
					<div className="block">
						<p>
							<ArrowButton 
								href={`https://www.flickr.com/photos/benchevents/albums/72177720303661061`} 
								target={`_blank`} 
								theme={{ arrow: `white`, border: `white`, label: `white`}}
							>See more photos</ArrowButton>
						</p>
					</div>
				</MontageBox>

				<MontageBox span={2} backgroundColor={`white`}>
					<MeetGraphicBase />
				</MontageBox>

			</MontageGrid>

			<section className="block bg-white">
				<div className="container center">
					<p className="fs-1 fw-700 lh-1 c-primary">Thank you to all our Sponsors <span>&amp; Partners</span></p>
				</div>
				                
                { GetBizzaboPartersByLevel( 2, bzPartners ) }
                { GetBizzaboPartersByLevel( 4, bzPartners ) }
                { GetBizzaboPartersByLevel( 5, bzPartners ) }
                { GetBizzaboPartersByLevel( 6, bzPartners ) }
                { GetBizzaboPartersByLevel( 7, bzPartners ) }
                { GetBizzaboPartersByLevel( 8, bzPartners ) }


			</section>


			<FeaturedContent>
				<div className="container mt-md columns-3 gap-md">
					{ featuredContent.map( (article) => 
						<ContentCard
							key={article.id}
							url={`/content-library/${article.slug}`}
							heroUrl={article.contentLibraryHero.length ? article.contentLibraryHero[0].url : null}
							heading={article.title}
							excerpt={article.excerpt}
							contentTypes={article.contentTypes}
							postDate={article.postDate}
						/> ) 
					}
				</div>
			</FeaturedContent>


			{/* <MeetGraphic /> */}

			


			


		</>
	)
}




export async function getStaticProps({ params, preview, previewData }) {

    const pageData = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Homepage {
				entry(site: "${process.env.SITE_HANDLE}", id: "22363") {
					id
					title
					... on ${process.env.SITE_HANDLE}Homepage_${process.env.SITE_HANDLE}Homepage_Entry {
						${QuerySEO}
					}
				}
			}
        `
    })


	const craftCmsProfiles = await craftApolloClient().query({
        query: gql`
            query Profiles {
                entry(site: "${process.env.SITE_HANDLE}", section: "profileGroups", id: "104759") {
					... on profileGroups_default_Entry {
						id
						title
						profiles {
							... on profiles_profile_Entry {
								id
								slug
								title
								avatar {
									... on avatars_Asset {
										id
										url
										width
										height
									}
								}
								positions {
									... on positions_BlockType {
										id
										jobTitle
										organisation
									}
								}
								profile
							}
						}
					}
				}
            }
          
        `
	})
    
	
	const contentLibraryData = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Articles {
				entries(site: "${process.env.SITE_HANDLE}", section: "contentLibrary",  id: [122272, 117129, 115304], limit: 3) {
					... on contentLibrary_article_Entry {
						id
						slug
						title
						postDate
						excerpt
						contentLibraryHero {
							id
							url
							width
							height
						}
						contentTypes {
							... on contentLibrary_Category {
								id
								title
							}
						}
					}
				}
			}
        `
    })


	const speakers = await fetch(`https://api.bizzabo.com/api/speakers?eventId=${process.env.BIZZABO_EVENT_ID}`, {
		"method": "GET",
		"headers": {
			"Content-Type": "application/json",
			"Authorization": "Bearer 67f61f9e-e897-4536-914e-3f47286650f9"
		}
	})
	const speakerData = await speakers.json()

	
    return { 
		props: { 
			seomatic: pageData.data.entry.seomatic,
			entry: pageData.data.entry,
			craftCmsProfiles: craftCmsProfiles.data.entry.profiles,
			featuredContent: contentLibraryData.data.entries,
			speakerData: speakerData.content
		}
	}
}