import { useRef } from "react"
import { motion, useScroll, useTransform } from 'framer-motion'

import Image from 'next/image'

import { Button } from '../../components/button/button'
import { HeaderShim } from '../../global/header/header'

import CSS from './hero-unit-post-event.module.css'

export const HeroUnit = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start start', 'end start']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["0%", "30%"])

    return (
        <>
            {/* <HeaderShim/> */}

            <section ref={ref} className={CSS.layout}>

                <div className={`${CSS.headline} formatted bg-secondary px-lg py-md`}>
                    <p className='fs-0 fw-600 lh-1'>THANK YOU FOR MAKING #IDEEA 2023 A SUCCESS!</p>
                    <p className='fs-4 mt-md'><Button href='https://www.linkedin.com/company/ideeaforum' decal={`fw-600 bg-white c-primary`}>Follow us on LinkedIn</Button></p>
                </div>

                <div className={CSS.hero}>
                    <motion.div className={CSS.heroWrapper} style={{ y }}>
                        <Image src={`/assets/theme/ideea-brand-hero.png`} width={800} height={900} layout={`responsive`} />
                    </motion.div>
                </div>

            </section>

        </>
    )
}