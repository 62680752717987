import { useRef } from "react"
import { motion, useScroll, useTransform } from 'framer-motion'

import Image from "next/image"

import CSS from './highlights-post-event.module.css'

export const HighLights = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start center', 'end start']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["0%", "30%"])


    return (
        <section ref={ref} className={CSS.layout}>

            <div className={`${CSS.impact} bg-accent p-lg`}>
                <p className="fs-0 lh-1 caps c-primary">IDEEA 2023 <br/>in numbers</p>
                <div className={`${CSS.impactGridThree} mt-md`}>
                    <ImpactFigure figure={`130`} label={`Companies`} />
                    <ImpactFigure figure={`28`} label={`Countries`} />
                    <ImpactFigure figure={`18%`} label={`Investor Audience`} />
                </div>
            </div>

        </section>
    )
}

const ImpactFigure = ({ figure, label }) => {
    return (
        <p className={CSS.impactLayout}>
            <span className={`${CSS.impactFigure} fs-lg fw-700 lh-1 c-primary`}>{ figure }</span>
            <span className={`${CSS.impactLabel} fs-5 fw-600 c-primary`}>{ label }</span>
        </p>
    )
}